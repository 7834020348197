<template>
    <div class="col d-sm-flex justify-content-center">
        <div class="card text-center">
            <h2>L'acquisto è andato a buon fine!</h2>
            <p>Grazie per aver acquistato nuove ore di guida. Le ore sono già disponibili</p>
            <br>
            <router-link to="/available-times-student" class="btn btn-primary btn-block">Guarda il tuo monte ore</router-link>
        </div>
    </div>
</template>

<script>

export default {
    name: "purchase-success",

};
</script>
